@media print {
  @page {size: 210mm 297mm; margin: 30mm;}

  header, 
  .react-formik-wizard__step-tabs,
  .react-formik-wizard__footer,
  .makeStyles-topCorner-6,
  .MuiTypography-body2,
  .makeStyles-printButtonContainer-386,
  .makeStyles-printButton-415 {
    display: none!important;
  }

  body {
    font-size: 12pt;
  }

  .MuiPaper-root {
    margin: 0;
  }
}